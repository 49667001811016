@import url("https://unpkg.com/@tailwindcss/typography@0.2.x/dist/typography.min.css");
@import url("https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css");
@import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Truculenta:wght@200&family=Work+Sans:ital,wght@0,300;1,100&display=swap");
body {
    background-color: #222e3f;
}
.heading {
    font-family: "Oswald", sans-serif;
}

.cursive {
    font-family: "Work Sans", sans-serif;
}

.prose img {
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
}

.home-name {
    font-family: "Nova Square", cursive;
}

.nav-link-font {
    font-family: "Oswald", sans-serif;
    font-size: 18px;
}

.nav-link-active {
    color: white;
}

.css-hight {
    height: 86vh;
}

.sublime-text {
    font-family: "Work Sans", sans-serif;
}

.project-title {
    font-family: "Oswald", sans-serif;
}

.project-info {
    font-family: "Work Sans", sans-serif;
}

.filter {
    border: 1px solid white;
    padding: 10px 20px;
    box-shadow: 2px 2px;
    border-radius: 10px;
    transition: 0.2s;
    cursor: pointer;
}
.filter:hover {
    color: #222e3f;
    background-color: white;
    box-shadow: 0 0;
}

.filter-active {
    border: 1px solid #222e3f;
    color: #222e3f;
    background-color: white;
    padding: 10px 20px;
    box-shadow: 0 0;
    border-radius: 10px;
    transition: 0.2s;
}

@media screen and (max-width: 500px) {
    .filter {
        font-size: 12px;
        margin: 0 auto;
        text-align: center;
    }
    .filter-active {
        font-size: 12px;
        margin: 0 auto;
        text-align: center;
    }
}
