@import url(https://unpkg.com/@tailwindcss/typography@0.2.x/dist/typography.min.css);
@import url(https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css);
@import url(https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Truculenta:wght@200&family=Work+Sans:ital,wght@0,300;1,100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Inconsolata&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600&family=Quattrocento&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
body {
    background-color: #222e3f;
}
.heading {
    font-family: "Oswald", sans-serif;
}

.cursive {
    font-family: "Work Sans", sans-serif;
}

.prose img {
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
}

.home-name {
    font-family: "Nova Square", cursive;
}

.nav-link-font {
    font-family: "Oswald", sans-serif;
    font-size: 18px;
}

.nav-link-active {
    color: white;
}

.css-hight {
    height: 86vh;
}

.sublime-text {
    font-family: "Work Sans", sans-serif;
}

.project-title {
    font-family: "Oswald", sans-serif;
}

.project-info {
    font-family: "Work Sans", sans-serif;
}

.filter {
    border: 1px solid white;
    padding: 10px 20px;
    box-shadow: 2px 2px;
    border-radius: 10px;
    transition: 0.2s;
    cursor: pointer;
}
.filter:hover {
    color: #222e3f;
    background-color: white;
    box-shadow: 0 0;
}

.filter-active {
    border: 1px solid #222e3f;
    color: #222e3f;
    background-color: white;
    padding: 10px 20px;
    box-shadow: 0 0;
    border-radius: 10px;
    transition: 0.2s;
}

@media screen and (max-width: 500px) {
    .filter {
        font-size: 12px;
        margin: 0 auto;
        text-align: center;
    }
    .filter-active {
        font-size: 12px;
        margin: 0 auto;
        text-align: center;
    }
}

.container-home {
    width: 100%;
    height: 86vh;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    background-image: url(/static/media/hero.a8a88d7f.png); /* The image used */

    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-name {
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    font-weight: Semi-bold 600;
}
.slogan {
    font-family: "Raleway", sans-serif;
}

.read-more-button {
    margin: 0 auto;
    color: white;
    font-size: 1.3rem;
    font-family: "Bebas Neue", cursive;
    width: 200px;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 1rem;
    padding: 0.7rem;
    transition: 1s;
}

.read-more-button:hover {
    background-color: white;
    color: #222e3f;
}

.slogan {
    font-family: "Oswald", sans-serif;
    padding: 10px;
}

#hide-hover {
    display: none;
}
#show-hover {
    display: inline;
}

.github {
    color: white !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

