@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Inconsolata&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600&family=Quattrocento&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap");

.container-home {
    width: 100%;
    height: 86vh;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    background-image: url("./hero.png"); /* The image used */

    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-name {
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    font-weight: Semi-bold 600;
}
.slogan {
    font-family: "Raleway", sans-serif;
}

.read-more-button {
    margin: 0 auto;
    color: white;
    font-size: 1.3rem;
    font-family: "Bebas Neue", cursive;
    width: 200px;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 1rem;
    padding: 0.7rem;
    transition: 1s;
}

.read-more-button:hover {
    background-color: white;
    color: #222e3f;
}
