#hide-hover {
    display: none;
}
#show-hover {
    display: inline;
}

.github {
    color: white !important;
}
